.package-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: var(--white);
  transition: all .2s;
  border-radius: 8px;
  @media(max-width: 1023px) {
    box-shadow: 0px 4px 6px rgba(var(--charcoal-value), 0.1);
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    background-color: var(--white);
    z-index: 1;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    @media(min-width: 1024px) {
      border-radius: 8px;
      box-shadow: 0px 4px 6px rgba(var(--charcoal-value), 0.1);
    }

    .card-image {
      position: relative;
      flex-grow: 0;
      height: 200px;
      width: 100%;
      padding: 5px;
      background-size: 10000% 100%;
      background-position: top right;
      background-repeat: no-repeat;

      .card-bg {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 10000%;
        height: 100%;
        z-index: 0;
      }
      
      .bg-image {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        max-width: 270px;
        max-height: 200px;
        z-index: 0;
      }

      .card-image-badge {
        position: relative;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        width: 90px;
        height: 60px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 4px;
        z-index: 1;

        .image {
          flex-grow: 1;
          position: relative;
        }
      }
    }

    .card-body {
      display: flex;
      padding: 20px 12px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      flex-grow: 1;

      .title {
        font-size: 1.15rem;
        font-weight: 700;
        margin-bottom: 8px;
      }

      .card-info-badges {
        margin-bottom: 10px;
        min-height: 30px;
        span {
          display: inline-block;
          padding: 6px 10px;
          font-size: 10px;
          font-weight: 500;
          background-color: var(--cultured);
          color: var(--charcoal);
          user-select: none;
          border-radius: 6px;
          margin-bottom: 3px;
          margin-right: 4px;
          color: var(--white);
        }
      }

      .info {
        font-size: .875rem;
        font-weight: 400;
        list-style: none;
        
        li {
          position: relative;
          padding-left: 1.25rem;
          padding-bottom: .25rem;
          
          .checkmark {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            font-size: .875rem;
            font-weight: 400;
            transform: translateY(18%);
          }
        }

        &.bulleted {
          li {
            padding-left: .75rem;

            .checkmark {
              display: none;
            }
            &:before {
              content: '\2022';
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              font-size: 1.1rem;
              font-weight: 400;
              transform: translateY(-12%);
              }
          }
  
        }
      }
    }

    .pricing {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      padding: 0px 12px 20px 12px;

      .price {
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--honolulu-blue);
        margin-right: 12px;
      }
      .old-price {
        color: var(--lava);
        text-decoration: line-through;
      }
      .discount {
        color: var(--roman-silver);
        text-decoration: none;
        font-weight: bold;
      }

      .installments {
        color: var(--price-success);

        strong {
          white-space: nowrap;
        }
      }
    }
  }

  .action-buttons {
    position: relative;
    background-color: var(--white);
    display: flex;
    justify-content: stretch;
    gap: 12px;
    align-items: center;
    padding: 20px 12px;
    border-radius: 0 0 8px 8px;
    transition: all .2s;

    .btn {
      padding: 0 4px;
      border: 1px solid var(--honolulu-blue);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--honolulu-blue);
      font-weight: 700;
      height: 55px;
      max-width: calc(50% - 6px);
      flex-grow: 1;
      transition: all .2s;
      &:hover {
        opacity: .9;
      }
      
      &.details {
        background-color: var(--white);
      }
      &.buy-now {
        background-color: var(--honolulu-blue);
        color: var(--white);
      }
    }

    @media(min-width: 1024px) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      opacity: 0;
    }
  }

  @media(min-width: 1024px) {
    &:hover {
      box-shadow: 0px 25px 30px rgba(0, 0, 0, 0.08);
      transform: translateY(-20px);
      overflow: visible;
      z-index: 2;

      .card {
        box-shadow: none;
        border-radius: 8px 8px 0 0;
      }
      
      .action-buttons {
        opacity: 1;
        box-shadow: 0px 25px 30px rgba(0, 0, 0, 0.08);
        transform: translateY(100%);
      }
    }
  }
}
